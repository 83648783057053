import * as Images from './images';


export const countries = [
    { 
        lat: 48.716385, 
        lng: 21.261074, 
        name: "Slovakia", 
        photoSrc: () => { return Images.SLOVAKIA() },
        description: "Born and raised in this small, yet surprisingly rich country, I've had plenty of opportunities to explore it's magnificent nature. Although the towns don't have much to brag about, it's peaks, forests and meadows leave nothing more to be desired."
    },
    { 
        lat: 50.073658, 
        lng: 14.418540, 
        name: "Czech republic", 
        photoSrc: () => { return Images.CZECHIA() }, 
        description: "Czech republic is the country where I study, live, work and where I plan to spend the rest of my life, with the love of my life." 
    },
    { 
        lat: 52.3555, 
        lng: 1.1743, 
        name: "England", 
        photoSrc: () => { return [] },
        description: "It's been many years now since I've been to England, hence why most (meaning all) of my photos from there were consumed by time."
     },
    { 
        lat: 55.950558,
        lng: -3.185556,
        name: "Scotland",
        photoSrc: () => { return [] },
        description: "Scotland is one of the most beautiful countries I've ever been to. I've seen Edinburgh and Glasgow, as well as the surrounding highlands. I'm sad to say that those memories are now only in my head, since there is no photodocumentation of the trip left."
    },
    { 
        lat: 60.4720, 
        lng: 8.4689, 
        name: "Norway", 
        photoSrc: () => { return Images.NORWAY() },
        description: "The roadtrip to Norway is one of my most dear experiences. 2 weeks of travelling from Oslo all the way to Lofoten has been exhausting, but very enriching."
     },
    { 
        lat: 39.0742, 
        lng: 21.8243, 
        name: "Greece", 
        photoSrc: () => { return [] },
        description: "Our vacation on the island of Lefkada has been my first vacation by the sea together with my fiance. It was everything one could hope it to be. Greece has the most beautiful beaches I've seen to this day.",
    },
    { 
        lat: 45.1000, 
        lng: 15.2000, 
        name: "Croatia", 
        photoSrc: () => { return Images.CROATIA() },
        description: "Croatia has been our go-to family vacation destination ever since 2005. I've visited this country perhaps a dozen times and have always had a blast. I'm pretty sure I will be able to get more photos from my mom sometime in the future."
     },
    { 
        lat: 28.2916, 
        lng: -16.6291, 
        name: "Canaries", 
        photoSrc: () => { return Images.CANARIES() },
        description: "Canaries is probably the farthest I've been from Europe, while still technically being in Europe. In March, the temperatures don't usually go higher than 25°C, which is why it's probably the most comfortable \"summer\" vacation destination I've ever been to."
    },
    { 
        lat: 52.232361, 
        lng: 21.002769, 
        name: "Poland", 
        photoSrc: () => { return Images.POLAND() },
        description: "Poland is the country next door I don't really care for, but I'll be damned if rollercoasters aren't the best invention of mankind."
     },
     { 
         lat: 41.902782, 
         lng: 12.496366, 
         name: "Italy", 
         photoSrc: () => { return Images.ITALY() },
         description: "Unlike most people, when I say I've been to Italy, it means I've been to the Alps, not the beach."
      }
]