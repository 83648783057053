import './App.css';
import styled from "styled-components";
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer'

import SolarSystem from './components/solarsystem';
import { isMobile } from 'react-device-detect';
import Overlay from './components/overlay';
import CustomGallery from './components/gallery';
import { preloadImages } from './assets/images';

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  `;

function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
}

function App() {
  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [isOpen, setIsOpen] = React.useState(false)

  preloadImages();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
    if (isOpen) {
      setTitle("");
      setDescription("");
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const loaderElement = document.getElementById("loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    }, 3000);
  });

  if (isMobile) {
    return <div><h1>Sorry, I'm just a showcase project and am not able to run on mobile.</h1></div>
  }

  function Callback(name, photoSrc, description) {

    setImages(photoSrc !== undefined ? photoSrc() : []);
    setTitle(name);
    setDescription(description);
    toggleDrawer()

  }

  if (isLoading) {
    return null;
  }

  return <CanvasContainer>
    <Overlay title={title} description={description}></Overlay>
    <Canvas className='canvas'>
      <Suspense fallback={null}>
        <SolarSystem callback={Callback} />
      </Suspense>
    </Canvas>
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction='right'
      className="drawer"
      enableOverlay={true}
      overlayOpacity={0.9}
      zIndex={9}
      size={"40vw"}
    >
      <div className='gallery'>
        <CustomGallery images={images} />
      </div>
    </Drawer>

  </CanvasContainer>

}

export default App;
