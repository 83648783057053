import React, { Suspense, forwardRef, useState, useRef, useEffect } from 'react'

import EarthDayMap from "../assets/textures/8k_earth_daymap.jpg";
import EarthNormalMap from "../assets/textures/8k_earth_normal_map.jpg";
import EarthSpecularMap from "../assets/textures/8k_earth_specular_map.jpg";
import EarthClouds from "../assets/textures/8k_earth_clouds.jpg";
import MoonMap from "../assets/textures/moon_map.jpg";
import SunMap from "../assets/textures/sun_map.jpg";

import { countries } from '../assets/countries';

import Space from './space';

import { TextureLoader, DoubleSide, Vector3, Color, MeshBasicMaterial } from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls, Text } from "@react-three/drei";
import { EffectComposer, GodRays, SelectiveBloom, Selection, Select } from '@react-three/postprocessing'
import { BlendFunction, KernelSize, Resizer } from "postprocessing";

const RADIUS = 1;
const CLOUD_RADIUS = 1.009;
const COUNTRY_NAME_RADIUS = 1.03;

var earthRotation = 0;
const offset = new Vector3();

function calcPosFromLatLonRad(lat, lon, radius) {
    var phi = (90 - lat) * (Math.PI / 180);
    var theta = (lon + 180) * (Math.PI / 180);

    var x = -(radius * Math.sin(phi) * Math.cos(theta));
    var z = (radius * Math.sin(phi) * Math.sin(theta));
    var y = (radius * Math.cos(phi));

    return { x, y, z };
}

function SolarSystem({ callback }) {

    const [colorMap, normalMap, specularMap, cloudsMap, moonMap, sunMap] =
        useLoader(TextureLoader, [EarthDayMap, EarthNormalMap, EarthSpecularMap, EarthClouds, MoonMap, SunMap]);

    const earthRef = useRef();
    const moonRelativeToEarthRef = useRef();
    const moonRef = useRef();
    const sunRef = useRef();
    const sunRelativeToEarthRef = useRef();
    const earthGeometry = useRef();
    const cloudsGeometry = useRef();
    const cloudsRef = useRef();

    const [sunState, setSunState] = useState();

    let isInFocus = false;

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();

        moonRelativeToEarthRef.current.rotation.y = - elapsedTime / 16;
        moonRef.current.rotation.y = - elapsedTime / 14;
        cloudsRef.current.rotation.y = earthRef.current.rotation.y + elapsedTime / 128;
        cloudsRef.current.rotation.z = elapsedTime / 128;

        sunRelativeToEarthRef.current.rotation.y = elapsedTime / 10;
        sunRef.current.rotation.y = elapsedTime / 10;
    });

    useEffect(() => {
        if (sunRef.current) {
            setSunState(sunRef.current);
        }
    }, []);

    return <>
        <Space/>
        <mesh ref={moonRelativeToEarthRef}>
            <mesh ref={moonRef} position={[-3, 0, -2.5]}>
                <sphereGeometry args={[0.3, 32, 32]} />
                <meshStandardMaterial
                    map={moonMap}
                    metalness={0}
                    roughness={1} />
            </mesh>
        </mesh>

        <mesh ref={sunRelativeToEarthRef}>
            <mesh ref={sunRef} position={[40, 0, -25]}>
                <sphereGeometry args={[8, 32, 32]} />

                <meshStandardMaterial
                    map={sunMap}
                    />

                <pointLight intensity={0.8} color={'#fcbb42'}></pointLight>
                <ambientLight intensity={0.15}></ambientLight>
            </mesh>
        </mesh>

        <mesh ref={cloudsRef} onAfterRender={() => cloudsGeometry.current.normalizeNormals()}>
            <sphereGeometry ref={cloudsGeometry} args={[CLOUD_RADIUS, 32, 32]} />
            <meshPhongMaterial
                reflectivity={0}
                map={cloudsMap}
                opacity={0.4}
                depthWrite={true}
                transparent={true}
                side={DoubleSide} />
        </mesh>

        <mesh
            ref={earthRef} >
            <mesh onAfterRender={() => earthGeometry.current.normalizeNormals()}>
                <sphereGeometry ref={earthGeometry} args={[RADIUS, 32, 32]} />
                <meshPhongMaterial specularMap={specularMap} />
                <meshStandardMaterial
                    map={colorMap}
                    normalMap={normalMap}
                    metalness={0}
                    roughness={1} />

                <Countries callback={callback}></Countries>

                <OrbitControls
                    enableZoom={true}
                    enablePan={false}
                    enableRotate={true}
                    zoomSpeed={0.5}
                    panSpeed={0.5}
                    rotateSpeed={0.5}
                    minDistance={1.6}
                    maxDistance={2}
                />
            </mesh>
        </mesh>
        {sunState && (
            <EffectComposer multisampling={8}>
                <GodRays
                    sun={sunState}
                    blendFunction={BlendFunction.Screen}
                    samples={30}
                    density={0.97}
                    decay={0.96}
                    weight={0.10}
                    exposure={0.4}
                    clampMax={0.5}
                    width={Resizer.AUTO_SIZE}
                    height={Resizer.AUTO_SIZE}
                    kernelSize={KernelSize.SMALL}
                    blur={true}
                />
            </EffectComposer>
        )}</>;
}

function Word({ callback, name, photoSrc, description, ...props }) {
    const color = new Color()
    const fontProps = { fontSize: 0.013, letterSpacing: 0.05, lineHeight: 1, 'material-toneMapped': false }
    const ref = useRef()
    const [hovered, setHovered] = useState(false)
    const over = ((e) => (e.stopPropagation(), setHovered(true)))
    const out = () => setHovered(false)
    // Change the mouse cursor on hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer'
        return () => (document.body.style.cursor = 'auto')
    }, [hovered])
    // Tie component to the render-loop
    useFrame(({ camera }) => {
        // Make text face the camera
        ref.current.quaternion.copy(camera.quaternion);
        // Animate font color
        ref.current.material.color.lerp(color.set(hovered ? '#fa2720' : 'white'), 0.1);

    })
    return <Text ref={ref} onPointerOver={over} onPointerOut={out} onClick={() => callback(name, photoSrc, description)} {...props} {...fontProps} children={name} />
}

function Countries({ callback }) {
    const temp = []

    countries.forEach(country => {
        var position = calcPosFromLatLonRad(country.lat, country.lng, COUNTRY_NAME_RADIUS);
        temp.push([new Vector3(position.x, position.y, position.z), country.name, country.photoSrc, country.description])
    });

    var result = temp.map(([pos, name, photoSrc, description], index) => <Word key={index} position={pos} name={name} callback={callback} photoSrc={photoSrc} description={description} />);
    return result;
}

export default SolarSystem