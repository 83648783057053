import React from "react"

function Overlay({ title, description }) {

  return <div className="overlay">
    <div className="dot">
      <h1>{title === "" ? "Where have I been?" : title }</h1>
      {description === "" ? "Photogallery of Daniel's travels" : description }
    </div>
  </div>

};

export default Overlay
