import React from "react";
import { Stars } from "@react-three/drei";

function Space() {

    return <mesh>
            <Stars radius={500} depth={10} count={5000} factor={1} saturation={0} fade speed={1000}/>
        </mesh>;
}

export default Space


