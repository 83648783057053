import Slovakia1 from "../assets/photos/slovakia1.png";
import Slovakia2 from "../assets/photos/slovakia2.png";
import Slovakia3 from "../assets/photos/slovakia3.jpg";
import Slovakia4 from "../assets/photos/slovakia4.jpg";
import Slovakia5 from "../assets/photos/slovakia5.jpg";
import Slovakia6 from "../assets/photos/slovakia6.jpg";
import Slovakia7 from "../assets/photos/slovakia7.jpeg";
import Slovakia8 from "../assets/photos/slovakia8.jpg";
import Slovakia9 from "../assets/photos/slovakia9.jpeg";
import Slovakia10 from "../assets/photos/slovakia10.jpg";
import Slovakia11 from "../assets/photos/slovakia11.jpeg";
import Slovakia12 from "../assets/photos/slovakia12.jpg";
import Slovakia13 from "../assets/photos/slovakia13.jpg";
import Slovakia14 from "../assets/photos/slovakia14.jpg";
import Slovakia15 from "../assets/photos/slovakia15.jpg";
import Slovakia16 from "../assets/photos/slovakia16.jpg";
import Slovakia17 from "../assets/photos/slovakia17.jpg";


import Canaries1 from "../assets/photos/canaries1.jpg";
import Canaries2 from "../assets/photos/canaries2.jpg";
import Canaries3 from "../assets/photos/canaries3.jpeg";
import Canaries4 from "../assets/photos/canaries4.jpeg";
import Canaries5 from "../assets/photos/canaries5.jpg";
import Canaries6 from "../assets/photos/canaries6.jpeg";
import Canaries7 from "../assets/photos/canaries7.jpg";

import Croatia1 from "../assets/photos/croatia1.jpg";
import Croatia2 from "../assets/photos/croatia2.jpg";
import Croatia3 from "../assets/photos/croatia3.jpg";
import Croatia4 from "../assets/photos/croatia4.jpg";

import Czechia1 from "../assets/photos/czechia1.jpg";
import Czechia2 from "../assets/photos/czechia2.jpeg";
import Czechia3 from "../assets/photos/czechia3.jpg";
import Czechia4 from "../assets/photos/czechia4.jpg";
import Czechia5 from "../assets/photos/czechia5.jpg";
import Czechia6 from "../assets/photos/czechia6.jpg";

import Italy1 from "../assets/photos/italy1.jpg";
import Italy2 from "../assets/photos/italy2.jpg";
import Italy3 from "../assets/photos/italy3.jpg";

import Norway1 from "../assets/photos/norway1.jpeg";
import Norway2 from "../assets/photos/norway2.jpeg";
import Norway3 from "../assets/photos/norway3.jpg";
import Norway4 from "../assets/photos/norway4.jpg";
import Norway5 from "../assets/photos/norway5.jpg";
import Norway6 from "../assets/photos/norway6.jpg";
import Norway7 from "../assets/photos/norway7.jpg";
import Norway8 from "../assets/photos/norway8.jpeg";
import Norway9 from "../assets/photos/norway9.jpg";
import Norway10 from "../assets/photos/norway10.jpg";
import Norway11 from "../assets/photos/norway11.jpg";
import Norway12 from "../assets/photos/norway12.jpg";
import Norway13 from "../assets/photos/norway13.jpg";
import Norway14 from "../assets/photos/norway14.jpg";
import Norway15 from "../assets/photos/norway15.jpg";
import Norway16 from "../assets/photos/norway16.jpg";
import Norway17 from "../assets/photos/norway17.jpg";

import Poland1 from "../assets/photos/poland1.jpg";
import Poland2 from "../assets/photos/poland2.jpg";
import Poland3 from "../assets/photos/poland3.jpg";


const countriesDimensions = new Map();

function loadImgSize(imgSrc) {
  var newImg = new Image();

  newImg.src = imgSrc;

  newImg.onload = () => {
    countriesDimensions.set(imgSrc, {width: newImg.width, height: newImg.height})
  }
}

function getPhotoForGallery(imgSrc, caption, preload) {
    if (preload)
    {
      loadImgSize(imgSrc);
      return;
    }

    var dimensions = countriesDimensions.get(imgSrc);
    return {
      src: imgSrc,
      original: imgSrc,
      caption: caption,
      width: dimensions !== undefined ? dimensions.width : 0,
      height: dimensions !== undefined ? dimensions.height : 0,
    }
}

export function CANARIES(preload = false) {
  return [
  getPhotoForGallery(Canaries1, "Fuerteventura", preload),
  getPhotoForGallery(Canaries2, "Fuerteventura", preload),
  getPhotoForGallery(Canaries3, "Fuerteventura", preload),
  getPhotoForGallery(Canaries4, "Fuerteventura", preload),
  getPhotoForGallery(Canaries5, "Fuerteventura", preload),
  getPhotoForGallery(Canaries6, "Fuerteventura", preload),
  getPhotoForGallery(Canaries7, "Fuerteventura", preload),
]};

export function CZECHIA(preload = false) {
  return [
  getPhotoForGallery(Czechia1, "Attending weddings", preload),
  getPhotoForGallery(Czechia2, "Beskydy", preload),
  getPhotoForGallery(Czechia3, "Medlánky", preload),
  getPhotoForGallery(Czechia4, "Brno - Kraví hora", preload),
  getPhotoForGallery(Czechia5, "", preload),
  getPhotoForGallery(Czechia6, "", preload),
]};

export function ITALY(preload = false) { 
  return [
  getPhotoForGallery(Italy1, "", preload),
  getPhotoForGallery(Italy2, "", preload),
  getPhotoForGallery(Italy3, "", preload),
]};

export function NORWAY(preload = false) {
  return [
  getPhotoForGallery(Norway1, "", preload),
  getPhotoForGallery(Norway2, "", preload),
  getPhotoForGallery(Norway3, "", preload),
  getPhotoForGallery(Norway4, "", preload),
  getPhotoForGallery(Norway5, "", preload),
  getPhotoForGallery(Norway6, "", preload),
  getPhotoForGallery(Norway8, "", preload),
  getPhotoForGallery(Norway7, "", preload),
  getPhotoForGallery(Norway9, "", preload),
  getPhotoForGallery(Norway10, "", preload),
  getPhotoForGallery(Norway11, "", preload),
  getPhotoForGallery(Norway12, "", preload),
  getPhotoForGallery(Norway13, "", preload),
  getPhotoForGallery(Norway14, "", preload),
  getPhotoForGallery(Norway15, "", preload),
  getPhotoForGallery(Norway16, "", preload),
  getPhotoForGallery(Norway17, "", preload),
]};

export function POLAND(preload = false) {
  return [
  getPhotoForGallery(Poland1, "Energylandia", preload),
  getPhotoForGallery(Poland2, "Energylandia", preload),
  getPhotoForGallery(Poland3, "Energylandia", preload),
]};

export function SLOVAKIA(preload = false) {
  return [
    getPhotoForGallery(Slovakia1, "Vysoké Tatry - Rysy", preload),
    getPhotoForGallery(Slovakia2, "Malá Fatra", preload),
    getPhotoForGallery(Slovakia3, "Kubínska Hoľa", preload),
    getPhotoForGallery(Slovakia4, "", preload),
    getPhotoForGallery(Slovakia5, "", preload),
    getPhotoForGallery(Slovakia6, "", preload),
    getPhotoForGallery(Slovakia7, "", preload),
    getPhotoForGallery(Slovakia8, "", preload),
    getPhotoForGallery(Slovakia9, "", preload),
    getPhotoForGallery(Slovakia10, "", preload),
    getPhotoForGallery(Slovakia11, "", preload),
    getPhotoForGallery(Slovakia12, "", preload),
    getPhotoForGallery(Slovakia13, "", preload),
    getPhotoForGallery(Slovakia14, "", preload),
    getPhotoForGallery(Slovakia15, "", preload),
    getPhotoForGallery(Slovakia16, "", preload),
    getPhotoForGallery(Slovakia17, "", preload),
]};

export function CROATIA(preload = false) {
  return [
    getPhotoForGallery(Croatia1, "", preload),
    getPhotoForGallery(Croatia2, "", preload),
    getPhotoForGallery(Croatia3, "", preload),
    getPhotoForGallery(Croatia4, "", preload),
  ]
}

export function preloadImages() {
  SLOVAKIA(true);
  POLAND(true);
  NORWAY(true);
  ITALY(true);
  CZECHIA(true);
  CANARIES(true);
  CROATIA(true);
}